.container {
  margin: 10px 30px;
  font-family: "Lato", sans-serif;
}

.submit {
  background-color: #69ad5f;
  cursor: pointer;
  width: 130px;
  height: 50px;
  color: white;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.3s;
  padding: 5px 10px;
  margin-right: 20px;
}

.select {
  color: #0a445b;
}

.share {
  background-color: #0f566d;
  cursor: pointer;
  width: 130px;
  height: 50px;
  color: white;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  padding: 5px 10px;
}

.buttonWrapper {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
}

.submit:hover {
  background-color: #48943d;
}

.share:hover {
  background-color: #054a60;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}

.row > DIV {
  width: 48%;
}

.row DIV {
  border-radius: 0;
}

.range > DIV {
  font-size: 35px;
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: start;
  color: #0a445b;
}

@media (max-width: 767px) {
  .row {
    flex-direction: column;
    margin: 0px;
  }
  .row > DIV {
    width: 99%;
    margin-bottom: 20px;
  }
  .range > DIV {
    text-align: center;
  }
  .buttonWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .chartContainer {
    height: 800px;
  }
}
